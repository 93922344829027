import React, { Component, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ContentExplorer, ContentPicker } from 'box-ui-elements';

import { ReactComponent as Logo } from './filevine-white.svg';

import './index.css';

const BOX_SERVICE = process.env.REACT_APP_BOX_SERVICE_ENDPOINT;

function Recommendations({ recommendations, onDismiss, onLink }) {
  const [selectedFolderId, setSelectedFolderId] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    onLink(selectedFolderId);
    onDismiss();
  };

  const toggle = e => {
    if (e.target.checked) {
      setSelectedFolderId(e.target.value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="app__recommendations">
      <div className="app__recommendations-mask" />
      <div className="app__recommendations-inner">
        <h3>Select a Box folder</h3>
        <p>
          Recommendation{recommendations.length === 1 ? '' : 's'} based on your
          project name:
        </p>
        <div className="grid">
          {recommendations.map(({ id, name, path }) => (
            <label className="row" key={id} htmlFor={id}>
              <span className="cell">
                <input
                  type="radio"
                  name="selection"
                  id={id}
                  value={id}
                  checked={selectedFolderId === id}
                  onChange={toggle}
                />
              </span>
              <span className="cell">
                <svg
                  className="icon-folder-collab "
                  height="32"
                  viewBox="0 0 32 32"
                  width="32"
                  focusable="false"
                  role="presentation"
                >
                  <path
                    d="M27,25H5c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h8.6L16,9h11c0.6,0,1,0.4,1,1v14C28,24.6,27.6,25,27,25z"
                    fill="#42A2DB"
                  />
                  <path
                    d="M26.5,24h-21C5.2,24,5,23.8,5,23.5V12h22v11.6C27,23.8,26.8,24,26.5,24z"
                    fill="#CAE9FA"
                  />
                  <circle cx="13.6" cy="15.8" fill="#42A2DB" r="1" />
                  <circle cx="18.6" cy="15.8" fill="#42A2DB" r="1" />
                  <path
                    d="M13.6,17.5c-1.4,0-2.5,1.1-2.5,2.5v1.5h5V20C16,18.6,14.9,17.5,13.6,17.5z"
                    fill="#42A2DB"
                  />
                  <path
                    d="M17,21.4v-1.5c0-0.5-0.2-1-0.5-1.4c0.5-0.6,1.2-1,2-1c1.4,0,2.5,1.1,2.5,2.5v1.5h-4V21.4z"
                    fill="#42A2DB"
                  />
                </svg>
              </span>
              <ul className="cell cell--align-left">
                {path.map((p, index) => (
                  <li key={index} className="path">
                    {p}
                  </li>
                ))}
                <li>{name}</li>
              </ul>
            </label>
          ))}
        </div>
        <div className="app__recommendations-buttons">
          <button type="button" onClick={onDismiss}>
            Cancel
          </button>
          <button type="submit" className="link" disabled={!selectedFolderId}>
            Link
          </button>
        </div>
      </div>
    </form>
  );
}

function Wrapper({
  projectName,
  boxFolderId,
  message,
  action,
  recommendations,
  handleBoxFolderSelection,
  children
}) {
  const [showRecommendations, setShowRecommendations] = useState(false);

  useEffect(() => {
    if (
      !boxFolderId &&
      Array.isArray(recommendations) &&
      recommendations.length > 0
    ) {
      setShowRecommendations(true);
    }
  }, [boxFolderId, recommendations]);

  return (
    <div className="app">
      <div className="app__header">
        <Logo />
        <div className="app__project">{projectName}</div>
      </div>
      <div className="app__context">
        <span>{message}</span>
        {action}
      </div>
      <div className="app__box">{children}</div>
      {showRecommendations && (
        <Recommendations
          recommendations={recommendations}
          onDismiss={() => setShowRecommendations(false)}
          onLink={id => handleBoxFolderSelection([{ id }])}
        />
      )}
    </div>
  );
}

class App extends Component {
  state = {
    boxBusy: true,
    boxError: false,
    boxFolderId: '',
    boxFolderRecommendations: [],
    boxToken: '',
    busy: false,
    error: false,
    orgId: '',
    projectId: '',
    projectName: ''
  };

  /**
   * Retrieve the Box info
   */
  fetchBoxLink = async (orgId, projectId, projectName) => {
    try {
      this.setState({ boxBusy: true });

      const res = await fetch(`${BOX_SERVICE}/recommend`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ orgId, projectId, projectName })
      });

      if (!res.ok) {
        throw new Error('Not found');
      }

      this.setState({ boxBusy: false, ...(await res.json()) });
    } catch (error) {
      console.error('fetchBoxLink caught: ', error);
      this.setState({ boxBusy: false, boxError: true });
    }
  };

  /**
   * Link the selected Box folder to the project
   */
  handleBoxFolderSelection = async ([{ id }]) => {
    try {
      debugger;
      this.setState({ busy: true });

      const res = await fetch(`${BOX_SERVICE}/link`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          orgId: this.state.orgId,
          projectId: this.state.projectId,
          boxFolderId: id
        })
      });

      if (!res.ok) {
        throw new Error('Failed to link');
      }

      const { boxFolderId } = await res.json();

      this.setState({ busy: false, boxFolderId });
    } catch (error) {
      console.error('handleBoxFolderSelection caught:', error);
      this.setState({ busy: false, error: true });
    }
  };

  /**
   * Delete the link between the Box folder and the project
   */
  handleBoxFolderChangeRequest = async () => {
    if (
      window.confirm(
        'Are you sure you want to change the Box folder associated with this project?'
      )
    ) {
      try {
        this.setState({ busy: true });

        const { orgId, projectId, boxFolderId } = this.state;

        const res = await fetch(
          `${BOX_SERVICE}/${orgId}/${projectId}/${boxFolderId}`,
          {
            method: 'DELETE',
            headers: { 'content-type': 'application/json' }
          }
        );

        if (!res.ok) {
          throw new Error('Failed to delete');
        }

        this.setState({ busy: false, boxFolderId: null });
      } catch (error) {
        console.error('handleBoxFolderChangeRequest caught:', error);
        this.setState({ busy: false, error: true });
      }
    }
  };

  /**
   * Extract the query param values for org id, project id, and project name
   */
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    this.setState({
      orgId: params.get('orgid'),
      projectId: params.get('projectid'),
      projectName: params.get('projectname')
    });
  }

  /**
   * Handle state changes
   */
  componentDidUpdate(prevProps, prevState) {

    debugger;

    console.log('updated:', {
      prevProps,
      prevState,
      props: this.props,
      state: this.state
    });

    const { orgId, projectId, projectName } = this.state;

    const shouldFetch =
      projectId &&
      orgId &&
      (projectId !== prevState.projectId || orgId !== prevState.orgId);

    if (shouldFetch) {
      this.fetchBoxLink(orgId, projectId, projectName);
    }
  }

  render() {
    const isBusy = this.state.busy || this.state.boxBusy;
    const isInvalid = !this.state.orgId || !this.state.projectId;
    const hasError = this.state.error || this.state.boxError;
    const isMissingBoxFolderId = !this.state.boxFolderId;
    const canChangeFolder = !isBusy && !hasError && this.state.boxFolderId;

    const message = isBusy ? (
      <span className="loading-container">
        <span className="loading" />
        <span>Please wait...</span>
      </span>
    ) : hasError ? (
      'Please contact Filevine support.'
    ) : isInvalid ? (
      'Please return to Filevine.'
    ) : isMissingBoxFolderId ? (
      'Please select a Box folder to link to this project.'
    ) : (
      'You are viewing the Box folder linked to this project.'
    );

    const children =
      isBusy || hasError || isInvalid ? (
        <div className="washout" />
      ) : isMissingBoxFolderId ? (
        <IntlProvider locale="en" textComponent={React.Fragment}>
          <ContentPicker
            token={this.state.boxToken}
            canCreateNewFolder={false}
            canSetShareAccess={false}
            canUpload={false}
            logoUrl="box"
            maxSelectable={1}
            onChoose={this.handleBoxFolderSelection}
            type="folder"
          />
        </IntlProvider>
      ) : (
        <IntlProvider locale="en" textComponent={React.Fragment}>
          <ContentExplorer
            token={this.state.boxToken}
            rootFolderId={this.state.boxFolderId}
            logoUrl="box"
          />
        </IntlProvider>
      );

    const action = canChangeFolder && (
      <button type="button" onClick={this.handleBoxFolderChangeRequest}>
        Change Folder
      </button>
    );

    const wrapperProps = {
      orgId: this.state.orgId,
      projectId: this.state.projectId,
      projectName: this.state.projectName,
      boxFolderId: this.state.boxFolderId,
      recommendations: this.state.boxFolderRecommendations,
      handleBoxFolderSelection: this.handleBoxFolderSelection,
      message,
      children,
      action
    };

    return <Wrapper {...wrapperProps} />;
  }
}

ReactDOM.render(
  <Router>
    <Route path="/" component={App} />
  </Router>,
  document.getElementById('root')
);
